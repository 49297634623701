<template>
  <router-view />
</template>
<script setup ts>
import { onMounted } from "vue";

onMounted(() => {
  const screenWidth = window.screen.width;
  localStorage.setItem("ScreenWidth", screenWidth);

  (function() {
    var hm = document.createElement("script");
    hm.src = "https://hm.baidu.com/hm.js?d5f8f360f4827ffb0af487a6ac4ccfbc";
    var s = document.getElementsByTagName("script")[0]; 
    s.parentNode.insertBefore(hm, s);
  })();

  
});
</script>
<style lang="scss">
.page {
  font-size: 16px;
  padding: 50px 36px 50px;
  width: 100%;
  background-color: #fff;
  height: 100%;
  line-height: 28px;
  overflow-y: scroll;

  &-title {
    text-align: center;
    font-size: 26px;
    padding-bottom: 24px;
  }
  section,
  p {
    text-indent: 2em;
  }
}
</style>
<style lang="scss">
@media screen and (max-width: 1440px) {
  .page {
    font-size: 20px;
    line-height: 38px;
    padding: 50px;
    &-title {
      text-align: center;
      font-size: 46px;
      padding-bottom: 80px;
    }
  }
}
/* ipad pro */
@media screen and (max-width: 1024px) {
  .page {
      font-size: 48px;
      line-height: 74px;
      padding: 50px 36px 50px;
    &-title {
      text-align: center;
      font-size: 66px;
      padding-bottom: 80px;
    }
  }
}
</style>