import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/home/index.vue"),
  },
  {
    path: "/jump",
    name: "jump",
    component: () => import("@/views/jump/index.vue"),
  },
  {
    path: '/strategy',
    name: 'strategy',
    component: () => import('@/views/strategy/index.vue')
  },
  {
    path: '/detail/:id',
    name: 'detail',
    component: () => import('@/views/detail/index.vue')
  },
  {
    path: "/hop",
    name: "hop",
    component: () => import("@/views/hop/index.vue"),
  },
  {
    path: "/SDKlist",
    name: "SDKlist",
    component: () => import("@/views/SDKlist/index.vue"),
  },
  {
    path: "/ChildrenPrivacy",
    name: "ChildrenPrivacy",
    component: () => import("@/views/ChildrenPrivacy/index.vue"),
  },
  {
    path: "/AccountCancellation",
    name: "AccountCancellation",
    component: () => import("@/views/AccountCancellation/index.vue"),
  },
  {
    path: "/agreement",
    name: "agreement",
    component: () => import("@/views/agreement/index.vue"),
  },
  {
    path: "/privacy",
    name: "privacy",
    component: () => import("@/views/privacy/index.vue"),
  },
  {
    path: "/informationList",
    name: "informationList",
    component: () => import("@/views/informationList/index.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});


router.beforeEach((to, from, next) => {
  next();
});

export default router;
